import {
  createRouter,
  createWebHistory
} from "vue-router";
import Home from '@/layout/page.vue';
import HomeH5 from '@/layout/pageH5.vue';

const router = createRouter({
  history: createWebHistory("/induDoor/"),
  routes: [{
    name: '产业门户',
    path: '/',
    component: Home,
    children: [{
        path: '/',
        name: '首页1',
        component: () => import('@/views/shopDecoration/views/index.vue'),
      },
      {
        path: "/special",
        name: "专题页",
        component: () => import("@/views/shopDecoration/views/special.vue"),
      },
      {
        path: 'information',
        name: '首页2',
        component: () => import('@/views/secondPage/secondPage.vue'),
      },
      {
        path: 'baseMoudle',
        name: '首页3',
        component: () => import('@/views/baseMoudle/baseMoudle.vue'),
      },
      {
        path: 'aboutUs',
        name: '首页4',
        component: () => import('@/views/aboutUs/aboutUs.vue'),
      },
      {
        path: 'newsDetail',
        name: '首页5',
        component: () => import('@/views/newsDetails/newsDetails.vue'),
      },
      {
        path: 'standard',
        name: '首页6',
        component: () => import('@/views/standard/standard.vue'),
      },
      {
        path: 'searchPage',
        name: '首页7',
        component: () => import('@/views/searchPage.vue'),
      },
      {
        path: '/shopmall',
        name: '店铺交易商城列表页',
        component: () => import('@/views/dealhome/goodsList.vue')
      },
      {
        path: '/shopIndex',
        name: '交易商城店铺',
        component: () => import('@/views/dealhome/shopIndex.vue')
      },
      {
        path: '/goodsDetails',
        name: '交易商城详情页',
        component: () => import('@/views/dealhome/goodsDetails.vue')
      },
      {
        path: '/varieties',
        name: '品种列表',
        component: () => import('@/views/varieties/index.vue')
      },
      {
        path: '/varietiesDetails',
        name: '品种详情',
        component: () => import('@/views/varieties/details.vue')
      },
      {
        path: '/iframePage',
        name: '嵌入页面',
        component: () => import('@/views/iframePage/index.vue')
      },
      {
        path: '/qualityAscend',
        name: '质量追溯',
        component: () => import('@/views/qualityAscend.vue')
      },
    ],
  }, 
  {
    name: '产业门户H5',
    path: '/h5',
    component: HomeH5,
    children: [{
        path: 'index',
        name: '首页H5',
        component: () => import('@/views/shopDecoration/views/indexH5.vue'),
      },
      {
        path: "special",
        name: "专题页H5",
        component: () => import("@/views/shopDecoration/views/specialH5.vue"),
      },
      {
        path: 'information',
        name: '资讯分类H5',
        component: () => import('@/views/mobile/views/news/list.vue'),
      },
      {
        path: 'newsDetail',
        name: '资讯详情H5',
        component: () => import('@/views/mobile/views/news/detail.vue'),
      },
    ],
  }, 
  // {
  //   path: '/indexH5',
  //   name: '首页H5',
  //   component: () => import('@/views/shopDecoration/views/indexH5.vue'),
  // },
  // {
  //   path: "/specialH5",
  //   name: "专题页H5",
  //   component: () => import("@/views/shopDecoration/views/specialH5.vue"),
  // },
  {
    path: "/output",
    name: "专题输出页",
    component: () => import("@/views/shopDecoration/views/special.vue"),
  },
  {
    path: '/Pdfcomp',
    name: 'pdf预览',
    component: () => import('@/views/pdf/Pdfcomp.vue')
  },
  {
    path: '/excel',
    name: 'excel预览',
    component: () => import('@/views/newsDetails/xls.vue')
  },
  {
    path: '/pdf',
    name: 'pdf预览',
    component: () => import('@/views/newsDetails/pdf.vue')
  },
  {
    path: '/word',
    name: 'word预览',
    component: () => import('@/views/newsDetails/word.vue')
  },
  {
    name: '外链接品种',
    path: '/varietiesLink',
    component: () => import('@/views/varieties/layout/center.vue'),
    children: [
      {
        path: '',
        name: '外链品种列表',
        component: () => import('@/views/varieties/index.vue')
      },
      {
        path: 'details',
        name: '外链品种详情页',
        component: () => import('@/views/varieties/details.vue')
      },
    ],
  },
  {
    name: '交易商城外链接',
    path: '/shopmallLink',
    component: () => import('@/views/dealhome/layout/center.vue'),
    children: [
      {
        path: '',
        name: '外链商城列表',
        component: () => import('@/views/dealhome/goodsList.vue')
      },
      {
        path: 'details',
        name: '外链商城详情页',
        component: () => import('@/views/dealhome/goodsDetails.vue')
      },
    ],
  },
],
  scrollBehavior() {
    return {
      top: 0
    };
  },
});

// createRouteGuard(router);
export default router;